this["component-composer"]["views"]["table"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "--formatted";
},"3":function(container,depth0,helpers,partials,data) {
    return "--condensed";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <thead>\n          <tr class='table__row'>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.headers : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasActions : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n          </tr>\n        </thead>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "              <th class='table__header'>\n                "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"table-header",depth0,{"name":"component","hash":{},"data":data}))
    + "\n              </th>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "              <th class='table__header-action'></th>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <tr class='table__row "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depths[1] != null ? depths[1].rowPanels : depths[1])) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'>\n"
    + ((stack1 = helpers.each.call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].hasActions : depths[1]),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depths[1] != null ? depths[1].rowPanels : depths[1])) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "--expandable";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <td class='table__cell'>\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.props : depth0)) != null ? stack1.isIcon : stack1),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n              "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(alias1,"cell",depth0,{"name":"component","hash":{},"data":data}))
    + "\n            </td>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <div class='table__cell-title'>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.props : depth0)) != null ? stack1.title : stack1), depth0))
    + ":\n                  </div>\n";
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <td class='table__cell table__cell-action' data-index='"
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "'>\n                "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].viewModal : depths[1]),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].editModal : depths[1]),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].removeModal : depths[1]),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depths[1] != null ? depths[1].rowPanels : depths[1])) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </td>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "<button class='table__cell-action--view'><i class='fas fa-eye'></i></button>";
},"19":function(container,depth0,helpers,partials,data) {
    return "<button class='table__cell-action--edit'><i class='fas fa-pencil-alt'></i></button>";
},"21":function(container,depth0,helpers,partials,data) {
    return "<button class='table__cell-action--delete'><i class='fas fa-trash-alt'></i></button>";
},"23":function(container,depth0,helpers,partials,data) {
    return "<button class='table__cell-action--expand'><i class='fas fa-angle-down'></i></button>";
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "          <tr class='table__panel-row "
    + ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,(depths[1] != null ? depths[1].rowPanels : depths[1]),(data && data.index),{"name":"lookup","hash":{},"data":data}),{"name":"with","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'>\n            <td class='table__panel-cell' colspan='"
    + alias2(container.lambda((depths[1] != null ? depths[1].rowPanelColspan : depths[1]), depth0))
    + "'>\n              "
    + alias2((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(alias1,"row-panel",helpers.lookup.call(alias1,(depths[1] != null ? depths[1].rowPanels : depths[1]),(data && data.index),{"name":"lookup","hash":{},"data":data}),{"name":"component","hash":{},"data":data}))
    + "\n            </td>\n          </tr>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.props : depth0)) != null ? stack1.expanded : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    return "--expanded";
},"29":function(container,depth0,helpers,partials,data) {
    return "  <div class='table__footer'>\n    <button class='button button--filled'>\n      <i class=\"fas fa-plus\"></i>\n    </button>\n  </div>\n  "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal",(depth0 != null ? depth0.addModal : depth0),{"name":"component","hash":{},"data":data}))
    + "\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal",(depth0 != null ? depth0.editModal : depth0),{"name":"component","hash":{},"data":data}))
    + "\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal",(depth0 != null ? depth0.removeModal : depth0),{"name":"component","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class='table__wrapper'>\n  <table class='table "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.formatted : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.condensed : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showHeaders : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <tbody>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.cells : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\n  </table>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.addModal : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.editModal : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.removeModal : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});