this["component-composer"]["views"]["pagination"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "pagination__page__number--selected";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <span class='pagination__page'>\n      <button class='pagination__prev-pages'>\n        ...\n      </button>\n    </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "    <span class='pagination__page'>\n      <button\n        class='pagination__page__number "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'\n        data-page='"
    + ((stack1 = alias1((depth0 != null ? depth0.pageNumber : depth0), depth0)) != null ? stack1 : "")
    + "'>\n          "
    + container.escapeExpression(alias1((depth0 != null ? depth0.pageNumber : depth0), depth0))
    + "\n        </button>\n    </span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    <span class='pagination__page'>\n      <button class='pagination__next-pages'>\n        ...\n      </button>\n    </span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "    <span class='pagination__page pagination__page--last'>\n      <button\n      class='pagination__page__number "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.lastPage : depth0)) != null ? stack1.selected : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'\n      data-page='"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.lastPage : depth0)) != null ? stack1.pageNumber : stack1), depth0)) != null ? stack1 : "")
    + "'>\n        "
    + container.escapeExpression(alias1(((stack1 = (depth0 != null ? depth0.lastPage : depth0)) != null ? stack1.pageNumber : stack1), depth0))
    + "\n      </button>\n    </span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda;

  return "<div class='pagination'>\n  <button class='pagination__prev-page'>\n    <i class='fas fa-angle-left'></i>\n  </button>\n  <span class='pagination__page pagination__page--first'>\n    <button\n      class='pagination__page__number "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.firstPage : depth0)) != null ? stack1.selected : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'\n      data-page='"
    + ((stack1 = alias2(((stack1 = (depth0 != null ? depth0.firstPage : depth0)) != null ? stack1.pageNumber : stack1), depth0)) != null ? stack1 : "")
    + "'>\n      "
    + container.escapeExpression(alias2(((stack1 = (depth0 != null ? depth0.firstPage : depth0)) != null ? stack1.pageNumber : stack1), depth0))
    + "\n    </button>\n  </span>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.leftOverflow : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.pages : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.rightOverflow : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lastPage : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <button class='pagination__next-page'>\n    <i class='fas fa-angle-right'></i>\n  </button>\n</div>\n";
},"useData":true});