this["component-composer"]["views"]["calendar-date-input"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='input-group dropdown-calendar' data-model=\""
    + alias3((helpers.jsonStringify || (depth0 && depth0.jsonStringify) || alias2).call(alias1,depth0,{"name":"jsonStringify","hash":{},"data":data}))
    + "\">\n\n  "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"date-input",(depth0 != null ? depth0.dateInputData : depth0),{"name":"component","hash":{},"data":data}))
    + "\n\n  <div class=\"dropdown-calendar__content\">\n    <div class=\"close-calendar\">\n      <span class=\"close-calendar__button\"></span>\n    </div>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"calendar",(depth0 != null ? depth0.calendarData : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n</div>\n";
},"useData":true});