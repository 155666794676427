this["component-composer"]["views"]["tree-leaf"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " main-leaf--all-selected";
},"3":function(container,depth0,helpers,partials,data) {
    return " main-leaf--all-selected ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <span class='main-leaf__expander'>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"SEE",{"name":"translate","hash":{},"data":data}))
    + " "
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.leaves : depth0)) != null ? stack1.length : stack1), depth0))
    + " "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"NODES",{"name":"translate","hash":{},"data":data}))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " nested-leaves--visible";
},"9":function(container,depth0,helpers,partials,data) {
    return "      "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"as",depth0,{"name":"component","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class='tree__leaf'>\n  <div class='tree__leaf main-leaf "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hasChildren : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.areAllChildrenSelected : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'>\n    "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(alias1,"as",(depth0 != null ? depth0.checkbox : depth0),{"name":"component","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.leaves : depth0)) != null ? stack1.length : stack1),"!==",0,{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n\n  <div class='tree__leaf nested-leaves"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isExpanded : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.leaves : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>";
},"useData":true});