this["component-composer"]["views"]["agreement-settings"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='agreement-input'>\n  "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"select-input",(depth0 != null ? depth0.agreementSelector : depth0),{"name":"component","hash":{},"data":data}))
    + "\n</div>\n\n"
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"operator-sales-areas",(depth0 != null ? depth0.operatorSalesAreasTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n\n"
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"operator-projects",(depth0 != null ? depth0.operatorProjects : depth0),{"name":"component","hash":{},"data":data}))
    + "\n";
},"useData":true});