this["component-composer"]["views"]["calendar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"weekdays__name\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "          "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"calendar-date",depth0,{"name":"component","hash":{},"data":data}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        <div class=\"month-picker__name "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"==",(depths[1] != null ? depths[1].monthName : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "month-picker__name--active";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        <div class=\"month-picker__name "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"==",(depths[1] != null ? depths[1].shortMonthName : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " month-picker__name--short\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "visible";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        <div class=\"year-picker__year "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"==",(depths[1] != null ? depths[1].year : depths[1]),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "year-picker__year--active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div class=\"calendar\"  data-model=\""
    + alias3((helpers.jsonStringify || (depth0 && depth0.jsonStringify) || alias2).call(alias1,depth0,{"name":"jsonStringify","hash":{},"data":data}))
    + "\">\n  <div class=\"calendar__header header\">\n    <div class=\"header__month month\">\n      <button class=\"month__prev\">\n        <i class=\"arrow arrow--big arrow--left\"></i>\n      </button>\n      <div class=\"month__names names\">\n        <div class=\"names__name names__name--prev-name\">\n          "
    + alias3(((helper = (helper = helpers.prevMonthName || (depth0 != null ? depth0.prevMonthName : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"prevMonthName","hash":{},"data":data}) : helper)))
    + "\n        </div>\n        <div class=\"names__name names__name--current-name\">\n          "
    + alias3(((helper = (helper = helpers.monthName || (depth0 != null ? depth0.monthName : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"monthName","hash":{},"data":data}) : helper)))
    + "\n        </div>\n        <div class=\"names__name names__name--next-name\">\n          "
    + alias3(((helper = (helper = helpers.nextMonthName || (depth0 != null ? depth0.nextMonthName : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"nextMonthName","hash":{},"data":data}) : helper)))
    + "\n        </div>\n      </div>\n      <button class=\"month__next\">\n        <i class=\"arrow arrow--big  arrow--right\"></i>\n      </button>\n    </div>\n    <div class=\"header__year year\">\n        <button class=\"year__prev\">\n          <i class=\"arrow arrow--small arrow--left\"></i>\n        </button>\n        <div class=\"year__names names\">\n          <div class=\"names__name names__name--prev-name\">\n            "
    + alias3(((helper = (helper = helpers.prevYear || (depth0 != null ? depth0.prevYear : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"prevYear","hash":{},"data":data}) : helper)))
    + "\n          </div>\n          <div class=\"names__name names__name--current-name\">\n            "
    + alias3(((helper = (helper = helpers.year || (depth0 != null ? depth0.year : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"year","hash":{},"data":data}) : helper)))
    + "\n          </div>\n          <div class=\"names__name names__name--next-name\">\n            "
    + alias3(((helper = (helper = helpers.nextYear || (depth0 != null ? depth0.nextYear : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"nextYear","hash":{},"data":data}) : helper)))
    + "\n          </div>\n        </div>\n        <button class=\"year__next\">\n          <i class=\"arrow arrow--small arrow--right\"></i>\n        </button>\n    </div>\n  </div>\n\n  <div class=\"calendar__body body\">\n    <div class=\"body__day-picker day-picker\">\n      <div class=\"day-picker__weekdays weekdays\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.weekDays : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n\n      <div class=\"day-picker__days days\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.days : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"day-picker__today\">\n        <button class='button button--filled'>"
    + alias3(((helper = (helper = helpers.todayButtonLabel || (depth0 != null ? depth0.todayButtonLabel : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"todayButtonLabel","hash":{},"data":data}) : helper)))
    + "</button>\n      </div>\n    </div>\n\n    <div class=\"body__month-picker month-picker\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.monthNames : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.shortMonthNames : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"body__year-picker year-picker "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.yearPickerOpen : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      <button class=\"year-picker__prev-years prev-years\">\n\n        <div class=\"prev-years__container\">\n          <span class=\"prev-years__arrow prev-years__arrow--first\">\n            <i class=\"arrow arrow--left\"></i>\n          </span>\n          <span class=\"prev-years__arrow prev-years__arrow--second\">\n            <i class=\"arrow arrow--left\"></i>\n          </span>\n        </div>\n\n      </button>\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.yearPickerYears : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n      <button class=\"year-picker__next-years next-years\">\n        <div class=\"next-years__container\">\n          <span class=\"next-years__arrow next-years__arrow--first\">\n            <i class=\"arrow arrow--right\"></i>\n          </span>\n          <span class=\"next-years__arrow next-years__arrow--second\">\n            <i class=\"arrow arrow--right\"></i>\n          </span>\n        </div>\n      </button>\n    </div>\n\n  </div>\n</div>";
},"useData":true,"useDepths":true});