this["component-composer"]["views"]["orders-table-filters"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='rsp__xs100__md33 id'>\n  "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"id-input",(depth0 != null ? depth0.idInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n</div>\n<div class='rsp__xs100__md33 status'>\n  "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"select-input",(depth0 != null ? depth0.statusInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n</div>\n<div class='rsp__xs100__md33 hasChanges'>\n  "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"checkbox-input",(depth0 != null ? depth0.hasChangesInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n</div>";
},"useData":true});