this["component-composer"]["views"]["orders-list"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"section\">\n  <h2>\n    <span>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"ORDERS_LIST",{"name":"translate","hash":{},"data":data}))
    + "</span>\n  </h2>\n\n  <div class='rsp__xs100'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"orders-table-filters",(depth0 != null ? depth0.ordersTableFilters : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n\n  <div class='rsp__xs100'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"orders-table",(depth0 != null ? depth0.ordersTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n\n  <div class='rsp__xs33'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"button-group",(depth0 != null ? depth0.displayOptions : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs33'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"pagination",(depth0 != null ? depth0.pagination : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs33'>\n  </div>\n</div>\n<div class='rsp__xs100 orders-list__actions section-buttons'>\n  <button id='get-old-report__button' class='button button--wired'>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"GET_OLD_REPORT",{"name":"translate","hash":{},"data":data}))
    + "</button>\n\n  <button id='generate-report__button' class='button button--filled'>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"GENERATE_REPORT",{"name":"translate","hash":{},"data":data}))
    + "</button>\n</div>\n"
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"a",(depth0 != null ? depth0.snapshotModalForm : depth0),{"name":"component","hash":{},"data":data}));
},"useData":true});