this["component-composer"]["views"]["modal"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "--visible";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <button class='modal-actions__action button button--filled' data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n          "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n        </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <button class='modal-actions__confirm button button--filled'>\n          "
    + container.escapeExpression(((helper = (helper = helpers.confirmButton || (depth0 != null ? depth0.confirmButton : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"confirmButton","hash":{},"data":data}) : helper)))
    + "\n        </button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='modal "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOpen : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'>\n  <div class='modal-content'>\n    <div class='modal-header'>\n      "
    + alias3(((helper = (helper = helpers.headerText || (depth0 != null ? depth0.headerText : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"headerText","hash":{},"data":data}) : helper)))
    + "\n      <div class='close-modal'>\n        <span class='close-modal__button'></span>\n      </div>\n    </div>\n    <div class='modal-body'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"as",(depth0 != null ? depth0.body : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n    <div class='modal-footer'>\n      <button class='modal-actions__cancel button button--wired'>\n        "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"CANCEL",{"name":"translate","hash":{},"data":data}))
    + "\n      </button>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.actions : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.confirmButton : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>";
},"useData":true});