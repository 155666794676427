this["component-composer"]["views"]["order-ips-table-cell"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "  <span class='ip-prices "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.hasExceptionalPrice : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'>\n    "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.price : stack1), depth0))
    + alias3((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.currency : stack1),{"name":"translate","hash":{},"data":data}))
    + "\n  </span>\n  <span class='ip-quantity'>\n    ("
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.quantity : stack1), depth0))
    + ")\n  </span>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "exceptional-price";
},"4":function(container,depth0,helpers,partials,data) {
    return "  -\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});