this["component-composer"]["views"]["wholesale-operators"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"section\">\n  <div class='global-report'>\n    <button class='button button--filled'>\n      <i class='fas fa-file-download'></i> "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"DOWNLOAD_WHOLESALES_GLOBAL_REPORT",{"name":"translate","hash":{},"data":data}))
    + "\n    </button>\n  </div>\n\n  <div class='search-form'>\n    <div class='search-form__label'>\n      "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"SEARCH_OPERATOR_LABEL",{"name":"translate","hash":{},"data":data}))
    + "...\n    </div>\n    <div class='search-form__input'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorSearchInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n  </div>\n\n  <div class='operators-order rsp__xs100'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"button-group",(depth0 != null ? depth0.operatorOrderControls : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n\n  "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"wholesale-operators-list",(depth0 != null ? depth0.operatorsList : depth0),{"name":"component","hash":{},"data":data}))
    + "\n</div>\n";
},"useData":true});