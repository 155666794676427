this["component-composer"]["views"]["snapshot"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " <button id=\"confirm\"  class=\"button button--filled\">"
    + container.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"CLOSE_REPORT",{"name":"translate","hash":{},"data":data}))
    + "</button> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<h2>\n  <span>"
    + alias3((helpers.momentFormat || (depth0 && depth0.momentFormat) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.snapshot : depth0)) != null ? stack1.monthYearDate : stack1)) != null ? stack1.dateOnly : stack1),"MMMM YYYY",{"name":"momentFormat","hash":{},"data":data}))
    + " - "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"REPORT",{"name":"translate","hash":{},"data":data}))
    + "</span>\n</h2>\n\n<div class=\"rsp__xs100 snapshot\">\n  <div class=\"snapshot__operator\">\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"asf",(depth0 != null ? depth0.operatorSnapshotInfo : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class=\"snapshot__table\">\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"asf",(depth0 != null ? depth0.snapshotTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n</div>\n\n<div class=\"rsp__xs100 total\">\n  <div class=\"total__title\">\n    "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"TOTAL",{"name":"translate","hash":{},"data":data}))
    + "\n  </div>\n  <div class=\"total__quantity\">\n    "
    + alias3((helpers.numberToFixed || (depth0 && depth0.numberToFixed) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.total : depth0)) != null ? stack1.price : stack1),2,{"name":"numberToFixed","hash":{},"data":data}))
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.total : depth0)) != null ? stack1.currency : stack1),{"name":"translate","hash":{},"data":data}))
    + "\n  </div>\n</div>\n\n<div class=\"rsp__xs100 form-actions\">\n  <button id=\"go-back\" class=\"button button--wired\">"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"GO_BACK",{"name":"translate","hash":{},"data":data}))
    + "</button>\n  <button id=\"download\"  class=\"button button--filled\">"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"DOWNLOAD",{"name":"translate","hash":{},"data":data}))
    + "</button>\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCloseButton : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n\n"
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"as",(depth0 != null ? depth0.confirmSnapshotModal : depth0),{"name":"component","hash":{},"data":data}));
},"useData":true});