this["component-composer"]["views"]["project-specification"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='primary-title'>\n  "
    + alias3(((helper = (helper = helpers.selectedProject || (depth0 != null ? depth0.selectedProject : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"selectedProject","hash":{},"data":data}) : helper)))
    + "\n</div>\n<div class='grid-container'>\n  <div class='rsp__xs100 services'>\n    <span class='secondary-title'>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"SERVICES",{"name":"translate","hash":{},"data":data}))
    + "</span>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"operator-services-table",(depth0 != null ? depth0.projectServicesTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs100 ips'>\n    <span class='secondary-title'>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"FIXED_IPS",{"name":"translate","hash":{},"data":data}))
    + "</span>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"operator-ips-table",(depth0 != null ? depth0.projectIpsTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs50 charges'>\n      <span class='secondary-title'>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"CHARGES",{"name":"translate","hash":{},"data":data}))
    + "</span>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"operator-charges-table",(depth0 != null ? depth0.projectChargesTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs50 discounts'>\n    <span class='secondary-title'>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"DISCOUNTS",{"name":"translate","hash":{},"data":data}))
    + "</span>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"operator-charges-table",(depth0 != null ? depth0.projectDiscountsTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n</div>\n\n";
},"useData":true});