this["component-composer"]["views"]["wholesale-operators-list"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "    <div class=\"rsp__xs100__sm50__md33__lg25\">\n      <div class='operators-list__card "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.lastImportLog : depth0)) != null ? stack1.errors : stack1)) != null ? stack1.length : stack1),">",0,{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "' data-id='"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "'>\n        <div class='card__name'>\n          "
    + alias4(alias5((depth0 != null ? depth0.name : depth0), depth0))
    + "\n        </div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.lastImportLog : depth0)) != null ? stack1.errors : stack1)) != null ? stack1.length : stack1),">",0,{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div class='card__bottom'>\n          <div class='card__id'>\n            <div class='circle'>\n              "
    + alias4(alias5((depth0 != null ? depth0.id : depth0), depth0))
    + "\n            </div>\n          </div>\n          <div class='card__info info'>\n            <div>\n              <span class='info__label'>"
    + alias4((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"COMPLETED_ORDERS",{"name":"translate","hash":{},"data":data}))
    + "</span> "
    + alias4(((helper = (helper = helpers.completedOrdersCount || (depth0 != null ? depth0.completedOrdersCount : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"completedOrdersCount","hash":{},"data":data}) : helper)))
    + "\n            </div>\n            <div>\n              <span class='info__label'>"
    + alias4((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"LAST_BILLED",{"name":"translate","hash":{},"data":data}))
    + "</span> "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lastBillingMonthYear : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "--with-errors";
},"4":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"card__with-errors fa-stack\">\n            <i class=\"fas fa-exclamation-triangle\"></i>\n          </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression((helpers.momentFormat || (depth0 && depth0.momentFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.lastBillingMonthYear : depth0)) != null ? stack1.dateOnly : stack1),"MMMM YYYY",{"name":"momentFormat","hash":{},"data":data}))
    + " ";
},"8":function(container,depth0,helpers,partials,data) {
    return " - ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"operators-list\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.operatorsList : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});