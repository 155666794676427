this["component-composer"]["views"]["calendar-date"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "calendar-date--offset";
},"3":function(container,depth0,helpers,partials,data) {
    return "calendar-date--today";
},"5":function(container,depth0,helpers,partials,data) {
    return "calendar-date--active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div   data-model=\""
    + alias3((helpers.jsonStringify || (depth0 && depth0.jsonStringify) || alias2).call(alias1,depth0,{"name":"jsonStringify","hash":{},"data":data}))
    + "\"\n        class=\"calendar-date\n               "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.offset : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n               "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.today : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n               "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n  "
    + alias3(((helper = (helper = helpers.day || (depth0 != null ? depth0.day : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"day","hash":{},"data":data}) : helper)))
    + "\n</div>\n";
},"useData":true});