this["component-composer"]["views"]["report-operator-info"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='grid-container'>\n    <div class='rsp__xs50 name'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorName : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n    <div class='rsp__xs25 cif'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorFiscalNumber : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n    <div class='rsp__xs25 pulpo-id'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorPulpoId : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n</div>";
},"useData":true});