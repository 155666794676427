this["component-composer"]["views"]["operator-general-info"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"section\">\n  <h2><span>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"OPERATORS_GENERAL_INFO",{"name":"translate","hash":{},"data":data}))
    + "</span></h2>\n  <div class='general-info-form rsp__xs100'>\n    <div class='rsp__xs100__sm50__md33__lg25 name'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorName : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n    <div class='rsp__xs100__sm50__md33__lg25 fiscalName'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorFiscalName : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n    <div class='rsp__xs100__sm50__md33__lg25 fiscalNumber'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorFiscalNumber : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n    <div class='rsp__xs100__sm50__md33__lg25 wholesaleId'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorWholesaleId : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n    <div class='rsp__xs100__sm50__md33__lg25 pulpoId'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorPulpoId : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n    <div class='rsp__xs100__sm50__md33__lg25 selfinstaller'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorSelfinstaller : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n    <div class='rsp__xs100__sm50__md33__lg25 phone'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorPhone : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n    <div class='rsp__xs100__sm50__md33__lg25 email'>\n      "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.operatorEmail : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    </div>\n  </div>\n</div>";
},"useData":true});