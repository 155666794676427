this["component-composer"]["views"]["orders-billing"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='section'>\n  <h2>\n    <span>"
    + alias3((helpers.momentFormat || (depth0 && depth0.momentFormat) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ordersList : depth0)) != null ? stack1.props : stack1)) != null ? stack1.monthYearDate : stack1),"MMMM YYYY",{"name":"momentFormat","hash":{},"data":data}))
    + " - "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"ORDERS_SUMMARY",{"name":"translate","hash":{},"data":data}))
    + "</span>\n  </h2>\n\n  "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"orders-summary",(depth0 != null ? depth0.ordersSummary : depth0),{"name":"component","hash":{},"data":data}))
    + "\n</div>\n<div class='section'>\n  "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"orders-list",(depth0 != null ? depth0.ordersList : depth0),{"name":"component","hash":{},"data":data}))
    + "\n</div>  ";
},"useData":true});