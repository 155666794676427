this["component-composer"]["views"]["select-input-items"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <span class='dropdown__selected-tag'>\r\n      "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\r\n      <span class='dropdown__selected-remove'>&times;</span>\r\n    </span>\r\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class='dropdown__list-item "
    + ((stack1 = (helpers.exists || (depth0 && depth0.exists) || alias2).call(alias1,depth0,(depths[1] != null ? depths[1].selected : depths[1]),{"name":"exists","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " item' data-id='"
    + alias3((helpers.jsonStringify || (depth0 && depth0.jsonStringify) || alias2).call(alias1,(depth0 != null ? depth0.id : depth0),{"name":"jsonStringify","hash":{},"data":data}))
    + "'>\r\n      "
    + alias3(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.desc : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n    </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " --selected ";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "<span class='dropdown__item-description' data-id='"
    + alias1((helpers.jsonStringify || (depth0 && depth0.jsonStringify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),{"name":"jsonStringify","hash":{},"data":data}))
    + "'> ("
    + alias1(container.lambda((depth0 != null ? depth0.desc : depth0), depth0))
    + ")</span>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class='dropdown__selected'>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\r\n\r\n<div class='dropdown__list'>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});