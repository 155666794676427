this["component-composer"]["views"]["operator-projects-list"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "stack-list__button--selected";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.operatorProjects : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "      <div class='stack-list__button stack-list__button--project "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].selected : depths[1]),"==",(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "' data-project='"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "'>\n        "
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + " ("
    + alias2(alias1((depth0 != null ? depth0.code : depth0), depth0))
    + ")\n        <span class='remove-button'></span>\n      </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <button class='stack-list__button stack-list__button--empty'>\n      "
    + container.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"NO_PROJECTS",{"name":"translate","hash":{},"data":data}))
    + "\n    </button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='stack-list'>\n  <button class='stack-list__button stack-list__button--default "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selected : depth0),"==","DEFAULT",{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"DEFAULT_PRICES",{"name":"translate","hash":{},"data":data}))
    + "</button>\n  <div class='stack-list__title'>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"PROJECT_LIST",{"name":"translate","hash":{},"data":data}))
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.operatorProjects : depth0)) != null ? stack1.length : stack1),">",0,{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});