this["component-composer"]["views"]["orders-summary"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class='row'>\r\n      <div class='rsp__xs100 summary-table'>\r\n        "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"table",(depth0 != null ? depth0.summaryTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class='rsp__xs100'>\r\n      <span class='summary-info'>\r\n        ("
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"CALCULATIONS_FOR",{"name":"translate","hash":{},"data":data}))
    + "\r\n        <span class='summary-info__highlighted'> "
    + alias3((helpers.momentFormat || (depth0 && depth0.momentFormat) || alias2).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.summary : depth0)) != null ? stack1.currentMonthSummary : stack1)) != null ? stack1.monthYearDate : stack1)) != null ? stack1.dateOnly : stack1),"MMMM YYYY",{"name":"momentFormat","hash":{},"data":data}))
    + " </span>\r\n        "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"BASED_ON_ALL_BILLABLE_DATA_SINCE",{"name":"translate","hash":{},"data":data}))
    + "\r\n        <span class='summary-info__highlighted'> "
    + alias3((helpers.momentFormat || (depth0 && depth0.momentFormat) || alias2).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.summary : depth0)) != null ? stack1.lastSuccessfulImportLog : stack1)) != null ? stack1.dateTime : stack1)) != null ? stack1.dateTime : stack1),"LL",{"name":"momentFormat","hash":{},"data":data}))
    + " </span>)\r\n      </span>\r\n    </div>\r\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.summary : depth0)) != null ? stack1.lastImportLog : stack1)) != null ? stack1.success : stack1),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <div class=\"rsp__xs100\">\r\n        <div class='error-alert'>\r\n          <p>\r\n            "
    + container.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(alias1,"ORDER_SUMMARY_HAS_ERRORS",{"name":"translate","hash":{},"data":data}))
    + ":\r\n          </p>\r\n          <ul>\r\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.summary : depth0)) != null ? stack1.lastImportLog : stack1)) != null ? stack1.errors : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </ul>\r\n        </div>\r\n      </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "              <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.summary : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n<div class=\"form-actions rsp__xs100 --right\">\r\n  <button id=\"load-summary\" class=\"button button--filled\">"
    + container.escapeExpression((helpers.translate || (depth0 && depth0.translate) || helpers.helperMissing).call(alias1,"LOAD_SUMMARY",{"name":"translate","hash":{},"data":data}))
    + "</button>\r\n</div>\r\n\r\n\r\n\r\n\r\n";
},"useData":true});