this["component-composer"]["views"]["exceptional-price"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"grid-container\">\n  <div class=\"rsp__xs33 installation\">\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"input",(depth0 != null ? depth0.installationPriceInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class=\"rsp__xs33 subscription\">\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"input",(depth0 != null ? depth0.subscriptionPriceInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class=\"rsp__xs33 discounts\">\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"input",(depth0 != null ? depth0.discountsPriceInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n</div>";
},"useData":true});