this["component-composer"]["views"]["operator-menu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "operator-menu__item--active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.helperMissing;

  return "\n<div class='operator-menu section'>\n    <span class='operator-menu__item  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selectedItem : depth0),"==","general-info",{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "' data-id=\"general-info\" data-link=\"/operator/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.operator : depth0)) != null ? stack1.id : stack1), depth0))
    + "/general-info\">\n      "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias4).call(alias1,"GENERAL_INFO",{"name":"translate","hash":{},"data":data}))
    + "\n    </span>\n    <span class='operator-menu__item  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selectedItem : depth0),"==","agreement-settings",{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "' data-id=\"agreement-settings\" data-link=\"/operator/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.operator : depth0)) != null ? stack1.id : stack1), depth0))
    + "/agreement-settings\">\n      "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias4).call(alias1,"AGREEMENTS_SETTINGS",{"name":"translate","hash":{},"data":data}))
    + "\n    </span>\n    <span class='operator-menu__item  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selectedItem : depth0),"==","orders-billing",{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "' data-id=\"orders-billing\" data-link=\"/operator/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.operator : depth0)) != null ? stack1.id : stack1), depth0))
    + "/orders-billing\">\n      "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias4).call(alias1,"ORDERS_BILLING",{"name":"translate","hash":{},"data":data}))
    + "\n    </span>\n</div>\n";
},"useData":true});