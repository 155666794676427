this["component-composer"] = this["component-composer"] || {};
this["component-composer"]["views"] = this["component-composer"]["views"] || {};
this["component-composer"]["views"]["agreement"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='grid-container'>\n  <div class='rsp__xs100 name'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"editable-input",(depth0 != null ? depth0.nameInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs100 date-range'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"date-input",(depth0 != null ? depth0.dateRangeInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n</div>";
},"useData":true});