this["component-composer"]["views"]["product-addon"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='grid-container'>\n  <div class='rsp__xs50 start-date'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"date-input",(depth0 != null ? depth0.startDateInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs50 category'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"select-input",(depth0 != null ? depth0.addonCategoryInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs100 concept'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"select-input",(depth0 != null ? depth0.conceptInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs66 apply'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"checkbox",(depth0 != null ? depth0.applyExceptionalPriceInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs33 exceptional-price'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"number-input",(depth0 != null ? depth0.exceptionalPriceInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs100 comment'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"text-area-input",(depth0 != null ? depth0.commentInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n</div>";
},"useData":true});