this["component-composer"]["views"]["operator-projects"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"section\">\n  <h2 id=\"operator-projects\"><span>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"OPERATOR_PROJECTS",{"name":"translate","hash":{},"data":data}))
    + "</span></h2>\n\n  "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"project-selector",(depth0 != null ? depth0.projectSelector : depth0),{"name":"component","hash":{},"data":data}))
    + "\n\n  "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"project-specification",(depth0 != null ? depth0.projectSpecification : depth0),{"name":"component","hash":{},"data":data}))
    + "\n</div>";
},"useData":true});