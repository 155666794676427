this["component-composer"]["views"]["services"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='grid-container'>\n  <div class='rsp__xs33 areas'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"select-input",(depth0 != null ? depth0.areasInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs66 services'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"select-input",(depth0 != null ? depth0.servicesInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs33 installation-price'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"number-input",(depth0 != null ? depth0.installationPriceInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs33 service-price'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"number-input",(depth0 != null ? depth0.servicePriceInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class='rsp__xs33 min-duration'>\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"number-input",(depth0 != null ? depth0.minDurationInput : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n</div>";
},"useData":true});