this["component-composer"]["views"]["sidebar-menu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"sidebar-menu-item",depth0,{"name":"component","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"topnav\">\n\n  <span class=\"topnav__open-menu\">\n    <div></div>\n    <div></div>\n    <div></div>\n  </span>\n\n  <div class=\"topnav__logo logo\">\n    <div class=\"logo__image\">\n      <img src=\"/assets/img/logo.png\" title=\"Adamo\" />\n    </div>\n    <div class=\"logo__caption\">admin tool</div>\n  </div>\n</div>\n\n\n<aside>\n  <nav class=\"sidenav\" data-model=\""
    + container.escapeExpression((helpers.jsonStringify || (depth0 && depth0.jsonStringify) || helpers.helperMissing).call(alias1,depth0,{"name":"jsonStringify","hash":{},"data":data}))
    + "\">\n    <div class=\"close-menu\">\n      <span class=\"close-menu__button\"></span>\n    </div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.sidebarMenuItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </nav>\n</aside>\n\n";
},"useData":true});