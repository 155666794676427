this["component-composer"]["views"]["order-panel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"as",(depth0 != null ? depth0.exceptionalPriceApproval : depth0),{"name":"component","hash":{},"data":data}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression((helpers.component || (depth0 && depth0.component) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"as",(depth0 != null ? depth0.verifyOrder : depth0),{"name":"component","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div class=\"grid-container order-details__panel\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pendingToApproveExceptionalPrice : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"rsp__xs100__md66\">\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"events-claims",(depth0 != null ? depth0.orderServicesTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"events-claims",(depth0 != null ? depth0.installationAddonOrderProductTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"events-claims",(depth0 != null ? depth0.standardAddonOrderProductsTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"events-claims",(depth0 != null ? depth0.disconnectionAddonOrderProductTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n    "
    + alias3((helpers.component || (depth0 && depth0.component) || alias2).call(alias1,"fixed-ips",(depth0 != null ? depth0.fixedIpsTable : depth0),{"name":"component","hash":{},"data":data}))
    + "\n  </div>\n  <div class=\"rsp__xs100__md33\">\n    <div class=\"order-details__info\">\n      <h5>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"ADDRESS",{"name":"translate","hash":{},"data":data}))
    + ":</h5> "
    + alias3(((helper = (helper = helpers.address || (depth0 != null ? depth0.address : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"address","hash":{},"data":data}) : helper)))
    + "\n    </div>\n    <div class=\"order-details__info\">\n      <h5>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"ZIP_CODE",{"name":"translate","hash":{},"data":data}))
    + ":</h5> "
    + alias3(((helper = (helper = helpers.zipCode || (depth0 != null ? depth0.zipCode : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"zipCode","hash":{},"data":data}) : helper)))
    + "\n    </div>\n    <div class=\"order-details__info\">\n      <h5>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"LOCATION",{"name":"translate","hash":{},"data":data}))
    + ":</h5> "
    + alias3(((helper = (helper = helpers.location || (depth0 != null ? depth0.location : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"location","hash":{},"data":data}) : helper)))
    + "\n    </div>\n    <div class=\"order-details__info\">\n      <h5>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"INITIAL_FREE_PERIOD",{"name":"translate","hash":{},"data":data}))
    + ":</h5> "
    + alias3(((helper = (helper = helpers.initialFreePeriod || (depth0 != null ? depth0.initialFreePeriod : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"initialFreePeriod","hash":{},"data":data}) : helper)))
    + "  "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"MONTHS",{"name":"translate","hash":{},"data":data}))
    + "\n    </div>\n    <div class=\"order-details__info\">\n      <h5>"
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"MIN_DURATION",{"name":"translate","hash":{},"data":data}))
    + ":</h5> "
    + alias3(((helper = (helper = helpers.minDuration || (depth0 != null ? depth0.minDuration : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"minDuration","hash":{},"data":data}) : helper)))
    + " "
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,"MONTHS",{"name":"translate","hash":{},"data":data}))
    + "\n    </div>\n  </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasChangesSinceVerification : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});