this["component-composer"]["views"]["price-table-cell"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <span "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.isExceptional : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n    "
    + alias3((helpers.numberToFixed || (depth0 && depth0.numberToFixed) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.price : stack1),2,{"name":"numberToFixed","hash":{},"data":data}))
    + alias3((helpers.translate || (depth0 && depth0.translate) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.currency : stack1),{"name":"translate","hash":{},"data":data}))
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "class='exceptional-price'";
},"4":function(container,depth0,helpers,partials,data) {
    return "  -\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});